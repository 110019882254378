import { graphql, useStaticQuery } from 'gatsby';
import React from 'react'
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/autoplay';

import TestimonialCard from './TestimonialCard'

import * as styles from './Testimonials.module.scss'

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query TestimonialsQuery {
      allSanityTestimonial {
        nodes {
          name
          date
          score
          comment
        }
      }
    }
  `)

  const testimonials = data.allSanityTestimonial.nodes

  const slideElements = testimonials.map(({ id, name, date, score, comment }) => (
    <SwiperSlide key={id} className={styles.swiperSlide}>
      <TestimonialCard
        name={name}
        date={date}
        score={score}
        comment={comment}
      />
    </SwiperSlide>
  ))

  return (
    <section id="testimonials" className={styles.testimonials}>
      <h2>Grow<span className='lower'>One</span>Africa&trade; Testimonials</h2>
      <div className='my-swiper'>
        <div className={`${styles.swiperButton} swiper-button-prev`} />
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={25}
          slidesPerView={1}
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
          }}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next'
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          loop
        >
          {slideElements}
        </Swiper>
        <div className={`${styles.swiperButton} swiper-button-next`} />
      </div>
    </section>
  )
}

export default Testimonials