import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FaYoutube } from 'react-icons/fa'
import { PortableText } from '@portabletext/react'
import Showcase from '../components/Showcase'
import InfoBlock from '../components/InfoBlock'
import ClubsCarousel from '../components/ClubsCarousel'
import BrandsCarousel from '../components/BrandsCarousel'
import Footer from '../components/Layout/Footer'
import Testimonials from '../components/Testimonials'

import * as styles from '../styles/pages/clubs-page.module.scss'

export const Head = () => <title>Clubs | GROWoneAFRICA&trade;</title>

const ClubsPage = ({ data }) => {
  const {
    bannerImage,
    _rawTheGoaClubsSection,
    joinTheClubSection,
    proudlyGoaSection
  } = data.sanityClubsPage
  
  return (
    <>
      <main>
        {/* <Showcase image={bannerImage.asset} /> */}
        <Showcase image={bannerImage.asset} mainText={data.sanityClubsPage.mainText} subText={data.sanityClubsPage.subText} textVisible={data.sanityClubsPage.bannerTextVisible}/> {/* data.showcaseImage */}

        <InfoBlock 
          id="next"
          featuredImage={
            <iframe 
              title="GOA Clubs" 
              src="https://www.google.com/maps/d/embed?mid=1hcoQnSkD4VZ5qkV8ZY8SbmF2Hij8GEg&hl=en&ehbc=2E312F"
              className={styles.map} 
            />
          }
        >
          <h2>The GOA Clubs</h2>
          <PortableText value={_rawTheGoaClubsSection} />
          <Link to="/all-clubs/" className='btn-primary'>GOA Clubs</Link>
        </InfoBlock>
        <ClubsCarousel />
        <InfoBlock
          featuredImage={
            <GatsbyImage image={getImage(joinTheClubSection.featuredImage.asset)} alt="" />
          }
          reversed
        >
          <h2>Join the Club</h2>
          <PortableText value={joinTheClubSection._rawContent} />
          <a 
            href={data.sanityCompanyInfo.nmaVideoLink}
            target="_blank"
            rel="noreferrer"
            className='btn-primary-lg'
          >New Membership Video <FaYoutube /></a>
          <a 
            href={data.sanityCompanyInfo.membershipApplicationLink} 
            target="_blank"
            rel="noreferrer"
            className='btn-primary-lg'
          >Membership Application</a>          
        </InfoBlock>
        {/* PROUDLY GOA */}
        <InfoBlock
          featuredImage={
            <GatsbyImage image={getImage(proudlyGoaSection.featuredImage.asset)} alt="" />
          }
        >
          <h2>Proudly GOA</h2>
          <PortableText value={proudlyGoaSection._rawContent} />
          <Link to="/all-brands/" className='btn-primary'>GOA Brands</Link>
        </InfoBlock>
        <BrandsCarousel />
      </main>
      <Footer>
        <Testimonials />
      </Footer>
    </>
  )
}

export const query = graphql`
  query ClubsPageQuery {
    sanityClubsPage {
      bannerImage {
        asset {
          gatsbyImageData
        }
      }
      mainText
      subText
      bannerTextVisible

      _rawTheGoaClubsSection
      joinTheClubSection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        _rawContent
      }
      proudlyGoaSection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        _rawContent
      }
    }
    sanityCompanyInfo {
      membershipApplicationLink
      nmaVideoLink
    }
  }
`

export default ClubsPage
