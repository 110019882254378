import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/autoplay';

import * as styles from './ClubsCarousel.module.scss'
import { graphql, useStaticQuery } from 'gatsby';

const ClubsCarousel = () => {
  const data = useStaticQuery(graphql`
    query ClubsCarouselQuery {
      allSanityClub(sort: {fields: name}) {
        nodes {
          name
          logo {
            asset {
              gatsbyImageData(layout: FIXED, height: 175, width: 225, fit: MIN)
            }
          }
        }
      }
    }
  `)

  const clubs = data.allSanityClub.nodes

  const slideElements = clubs.map(({ name, logo }) => {
    return (
      <SwiperSlide key={name} className={styles.swiperSlide}>
        <GatsbyImage image={getImage(logo.asset)} alt={name} />
      </SwiperSlide>
    )
  })

  return (
    <section id="clubs" className={styles.clubs}>
      <div className="my-swiper">
        <div className={`${styles.swiperButton} swiper-button-prev`} />
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop
        >
          {slideElements}
          {/* <SwiperSlide className={styles.swiperSlide}>
            <StaticImage 
              src="../../images/logos/clubs/the-good-herb-logo.jpg" 
              alt="Avery Incorporated" 
              layout='fixed'
              height={175}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <StaticImage 
              src="../../images/logos/clubs/saint-blazed-logo.JPG" 
              alt="CleverCom" 
              layout='fixed'
              height={175}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <StaticImage 
              src="../../images/logos/clubs/ancient-river-valley-farms-logo.jpg" 
              alt="CCFO" 
              layout='fixed'
              height={175}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <StaticImage 
              src="../../images/logos/clubs/gaias-gift-logo.jpeg" 
              alt="CTAA" 
              layout='fixed'
              height={175}
            />
          </SwiperSlide> */}
        </Swiper>
        <div className={`${styles.swiperButton} swiper-button-next`} />
      </div>
    </section>
  )
}

export default ClubsCarousel