import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

import * as styles from './InfoBlock.module.scss'

/*
  Collapsed logic is only for mobile
*/

const InfoBlock = ({ children, featuredImage, reversed, ...restProps}) => {
  const [collapsed, setCollapsed] = useState(true)
  const classes = `${styles.infoBlock} ${reversed ? styles.reversed : ''}`

  const toggleCollapse = () => {
    setCollapsed(prevCollapsed => !prevCollapsed)
  }

  return (
    <section className={classes} {...restProps}>
      <article className={collapsed ? styles.collapsed : ''}>
        {children}
        <button className={styles.readMoreBtn} onClick={toggleCollapse}>
          {collapsed 
            ? <>Read More <FaChevronDown /></> 
            : <>Collapse <FaChevronUp /></>
          }
        </button>
      </article>
      {/* <div className={styles.featuredImageWrapper}> */}
        {featuredImage}
      {/* </div> */}
    </section>
  )
}

export default InfoBlock