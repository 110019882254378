import React from 'react'
import StarRating from '../../StarRating'

import * as styles from './TestimonialCard.module.scss'

const TestimonialCard = ({ name, date, score, comment }) => {
  const dateString = new Date(date).toLocaleDateString(undefined, { 
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  })

  return (
    <article className={styles.testimonialCard}>
      <div className={styles.midSection}>
        <h3 className={styles.name}>{name}</h3>
        {date && <small>{dateString}</small>}
      </div>
      <StarRating score={score} className={styles.stars} />
      <p className={styles.comment}>{comment}</p>
    </article>
  )
}

export default TestimonialCard