import React from 'react'

const StarRating = ({ score, ...restProps }) => {
  const stars = '★★★★★☆☆☆☆☆'.slice(5 - score, 10 - score)

  return (
    <div {...restProps}>{stars}</div>
  )
}

export default StarRating